import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [htmlContent, setHtmlContent] = useState('');
  const ws = useRef(null); // WebSocket referansı

  const fetchHtml = async () => {
    try {
      const response = await axios.post(
        'https://demoapi.magicpay.ai/payment/v1/card-payments/3ds-init',
        {
          "conversationId": "12354",
          "externalId": "125534",
          "additionalParams": "",
          "bankOrderId": "44",
          "buyerMemberId": 25,
          "callbackUrl": "http://example.com/callback",
          "card": {
            "cardAlias": "",
            "cardHolderName": "test user",
            "cardNumber": "5406697543211173",
            "cardToken": "",
            "cardUserKey": "",
            "cvc": "423",
            "expireMonth": "04",
            "expireYear": "27",
            "storeCardAfterSuccessPayment": true
          },
          "clientIp": "192.168.1.1",
          "currency": "EUR",
          "installment": 1,
          "installment_count": 1,
          "items": [
            {
              "externalId": "item123",
              "name": "Item1",
              "price": 99999
            }
          ],
          "paidPrice": 99999,
          "paymentChannel": "Online",
          "paymentGroup": "PRODUCT",
          "paymentPhase": "AUTH",
          "posAlias": "adyen",
          "price": 99999,
          "recurring": false,
          "retry": false,
          "walletPrice": 0
        },
        {
          headers: {
            'x-api-key': 'magic-F4MscFAxlvgtzXgqjjPkU6TAm176wZH9h7cAQKe0-i8',
            'x-rnd-key': '1',
            'x-auth-version': 'V1',
            'x-signature': 'e910597f637b4ccf66bdf05c6cb5f3c013866d194d724a743227db283a6d0ddc',
            'Content-Type': 'application/json'
          }
        }
      );
      setHtmlContent(response.data);
    } catch (error) {
      console.error('Error fetching HTML:', error);
    }
  };

  useEffect(() => {
    fetchHtml(); // Initial fetch

    const connectWebSocket = () => {
      ws.current = new WebSocket('wss://demoapi.magicpay.ai/ws');

      ws.current.onopen = () => {
        console.log('WebSocket connection opened');
      };

      ws.current.onmessage = (event) => {
        console.log('Message from server: ', event.data); // Gelen mesajları konsolda görüntüleyin
        fetchHtml(); // Fetch HTML content again on database change
      };

      ws.current.onclose = (event) => {
        console.log('WebSocket connection closed', event);
        // Bağlantı kapandığında tekrar bağlan
        setTimeout(connectWebSocket, 1000);
      };

      ws.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.current.close(); // Hata aldığında WebSocket'i kapat
      };
    };

    connectWebSocket();

    // Cleanup function
    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, []);

  return (
    <div className="App">
      <h1>RealTime  PF  Change</h1>
      <br /><br />
      <iframe
        title="contentFrame"
        style={{ width: '100%', height: '500px', border: '1px solid #000' }}
        srcDoc={htmlContent}
      ></iframe>
    </div>
  );
}

export default App;